import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Team = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="team" className="text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>With over 5 years of hands-on experience in mobile repair, coupled with industry certification, I bring a wealth of expertise to effectively diagnose, troubleshoot, and repair a wide range of mobile devices. Whether it's smartphones, tablets, or other gadgets, my comprehensive knowledge ensures swift and reliable solutions to any technical issue.</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 col-sm-6 team" style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
            <div className="thumbnail" style={{ margin: "auto", width: "300px", height: "400px" }}>
              <img src="img/portfolio/anshul.jpg" alt="..." className="team-img" style={{ display: "block", margin: "auto", maxWidth: "100%", maxHeight: "100%" }} />
              <div className="caption">
                <h4>Anshul Naik</h4>
                <p>Mobile Repair Expert</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
};
