import React from "react";
import { useEffect
} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Services = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div id="services" className="text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
           We at KarwarTech are  dedicated to provide reliable and high-quality repair solutions tailored to your device needs. Visit us today to experience unmatched expertise and customer service!
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <img src={d.icon} width="200" height="200" style={{borderRadius:'20px'}} class="zoom-img"></img>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
