import React, { useEffect, useState } from "react";
import axios from 'axios';
import Slider from "react-slick";
import Modal from "react-modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css"; // Import CSS file for custom styling

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
    width: '80%',
    maxWidth: '500px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

export const Gallery = (props) => {
  const [mobiles, setMobiles] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMobile, setSelectedMobile] = useState(null);

  useEffect(() => {
    axios.get('https://karwartech-backend.onrender.com/added-data')
      .then(res => {
        setMobiles(res.data);
      })
      .catch(err => {
        console.error('Error fetching mobile data:', err);
      });
  }, []);

  const openModal = (mobile) => {
    setSelectedMobile(mobile);
    setModalIsOpen(true);
  };

  const handleConfirmation = (sendViaWhatsApp) => {
    const { name, price } = selectedMobile;
    const message = `Hi, I'm interested in buying the ${name} for ${price}.`;

    if (sendViaWhatsApp) {
      const whatsappURL = "https://wa.me/917619153350?text=" + encodeURIComponent(message);
      window.open(whatsappURL, "_blank").focus();
    } else {
      const emailSubject = encodeURIComponent("New Customer Inquiry");
      const emailBody = encodeURIComponent(`Message: ${message}`);
      const gmailURL = `https://mail.google.com/mail/?view=cm&fs=1&to=anshulnaik@karwartech.com&su=${emailSubject}&body=${emailBody}`;
      window.open(gmailURL, "_blank");
    }
    setModalIsOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <div id="portfolio" className="text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
        <div className="container">
          <div className="section-title">
            <h2>Buy Phones</h2>
            <p>You can buy your phones in this section</p>
          </div>
          <div className="row">
            <div className="portfolio-items-wrapper">
              <div className="portfolio-items">
                {mobiles.length > 0 ? mobiles.map((mobile, i) => (
                  <div className="col-sm-6 col-md-4 col-lg-4 portfolio-item" key={mobile._id}>
                    <Slider {...settings}>
                      {mobile.filePaths.map((filePath, index) => (
                        <div key={index}>
                          <img
                            src={`https://karwartech-backend.onrender.com/${filePath}`}
                            alt={`${mobile.name} - Image ${index + 1}`}
                            className="custom-image"
                            style={{ maxWidth: '200px', maxHeight: '200px' }} // Apply inline styles
                          />
                        </div>
                      ))}
                    </Slider>
                    <div className="mobile-details">
                      <h3>{mobile.name}</h3>
                      <p>Price: {mobile.price}</p>
                      <button style={{ marginBottom: "30px" }} onClick={() => openModal(mobile)}>Buy Now</button>
                    </div>
                  </div>
                )) : <div>Loading...</div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Send via WhatsApp or Email"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2>Send via WhatsApp or Email</h2>
        <p>Do you want to send this message via WhatsApp?</p>
        <button onClick={() => handleConfirmation(true)}>Yes, via WhatsApp</button>
        <button onClick={() => handleConfirmation(false)}>No, via Email</button>
      </Modal>
    </>
  );
};
