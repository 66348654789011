import React, { useState } from "react";
import Modal from "react-modal";
import "../App.css";

const initialState = {
  name: "",
  email: "",
  message: "",
  contactNumber: "",
  dropdown1: "",
  dropdown2: "",
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
    width: '80%',
    maxWidth: '500px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

export const Contact = (props) => {
  const [formData, setFormData] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://dummy.restapiexample.com/api/v1/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Message sent successfully!");
        setSubmitted(true);
        clearState();
      } else {
        console.error("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const clearState = () => {
    setFormData(initialState);
  };

  const handleConfirmation = (sendViaWhatsApp) => {
    const { name, contactNumber, message, dropdown1, dropdown2 } = formData;

    if (!name || !contactNumber) {
      alert("Please fill in the required fields: Name and Contact Number.");
      return;
    }

    if (sendViaWhatsApp) {
      const whatsappURL = `https://wa.me/917619153350?text=
      Name of Customer: ${name}%0a
      Contact Number: ${contactNumber}%0a
      Mobile Company: ${dropdown1}%0a
      Problem: ${dropdown2}%0a
      Message: ${message}%0a`;
      window.open(whatsappURL, "_blank").focus();
    } else {
      const emailSubject = encodeURIComponent("New Customer Inquiry");
      const emailBody = encodeURIComponent(
        `Name of Customer: ${name}\n
        Contact Number: ${contactNumber}\n
        Mobile Company: ${dropdown1}\n
        Problem: ${dropdown2}\n
        Message: ${message}`
      );
      const emailRecipient = encodeURIComponent("anshulnaik@karwartech.com");
      const gmailURL = `https://mail.google.com/mail/?view=cm&fs=1&to=anshultech@karwartech.com&su=${emailSubject}&body=${emailBody}`;
      window.open(gmailURL, "_blank");
    }
    setModalIsOpen(false);
  };

  const optionsArray = ["iPhone", "Samsung", "Redmi", "Realme", "POCO", "Motorolla", "Vivo", "OnePlus", "Oppo", "IQOO"];
  const mobileProblemsArray = [
    "Cracked screen",
    "Battery replacement",
    "Water damage",
    "Charging port issues",
    "Software problems",
    "Speaker or microphone issues",
    "Camera repair",
    "Broken buttons (e.g., power, volume)",
    "Network or connectivity problems",
    "Phone not turning on",
    "Other issues",
  ];

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will get back to you as soon as possible.
                </p>
              </div>
              {submitted ? (
                <div className="confirmation-message">
                  <p>Thank you for your message! We will get back to you soon.</p>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                          value={formData.name}
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="contactNumber"
                          name="contactNumber"
                          className="form-control"
                          placeholder="Contact Number"
                          required
                          value={formData.contactNumber}
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <select
                      name="dropdown1"
                      id="dropdown1"
                      className="form-control"
                      value={formData.dropdown1}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled hidden>Mobile Company</option>
                      {optionsArray.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <select
                      name="dropdown2"
                      id="dropdown2"
                      className="form-control"
                      value={formData.dropdown2}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled hidden>Problem</option>
                      {mobileProblemsArray.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="button" className="btn btn-custom btn-lg" onClick={() => setModalIsOpen(true)}>
                    Send Message
                  </button>
                </form>
              )}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {"Keshavnaikwada, Kodibag, Karwar"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {"+91 7619153350"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {"anshulnaik@karwartech.com"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a onClick={() => window.location.href = "https://www.google.com/maps/place/KARWAR+TECH/@14.8309544,74.1306722,17z/data=!3m1!4b1!4m6!3m5!1s0x3bbe6125eae57609:0xa8ceacd5cc3ae7de!8m2!3d14.8309544!4d74.1332471!16s%2Fg%2F11s00t2lhv?entry=ttu"}>
                      <i className="fa fa-map-marker"></i>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => window.location.href = "https://www.instagram.com/karwar_tech/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => window.location.href = "https://www.youtube.com/channel/UCTKMas7TLoYGH4HbJk79J9g"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => window.location.href = "https://www.linkedin.com/in/anshul-naik-919158202/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
         
        </div>
        <p>&copy; 2023 Karwar Tech. Designed by Sahil Naik and Spoorti Naik</p>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Send via WhatsApp or Email"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2>Send via WhatsApp or Email</h2>
        <p>Do you want to send this message via WhatsApp?</p>
        <button onClick={() => handleConfirmation(true)}>Yes, via WhatsApp</button>
        <button onClick={() => handleConfirmation(false)}>No, via Email</button>
      </Modal>
    </div>
  );
};

export default Contact;
