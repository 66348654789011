import React, { useState, useEffect, useCallback } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import '../App.css';
import axios from 'axios';

export function Header() {
   const [sliderImages, setSliderImages] = useState([
      {
         url: 'image.png',
      },
      {
         url: 'now1.png',
      },
      {
         url: 'now3.png',
      },
   ]);

   const [sliderImages1, setSliderImages1] = useState([]);
   
   useEffect(() => {
      fetchSliderImages();
   }, []);

   const fetchSliderImages = async () => {
      try {
         const response = await axios.get('https://karwartech-backend.onrender.com/slider-images');
         const lastIndex = response.data.length - 1;
         const lastImageSet = response.data[lastIndex].filePaths.map(filePath => ({
            url: `https://karwartech-backend.onrender.com/${filePath}`
         }));
         setSliderImages1(lastImageSet);
      } catch (error) {
         console.log('Failed to fetch slider images', error);
      }
   };

   useEffect(() => {
      function handleResize() {
         const smallScreenImages = [
            {
               url: 'karwar-tech.png',
            },
            {
               url: 'karwar-tech.png',
            },
            {
               url: 'karwar-tech.png',
            },
         ];
         const normalScreenImages = [
            {
               url: 'image.png',
            },
            {
               url: 'now1.png',
            },
            {
               url: 'now3.png',
            },
         ];
         setSliderImages(window.innerWidth <= 500 ? smallScreenImages : sliderImages1.length ? sliderImages1 : normalScreenImages);
      }

      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
   }, [sliderImages1]);

   const handleButtonClick = useCallback(() => {
      // Scroll to contact section
      const contactSection = document.getElementById('contact');
      if (contactSection) {
         window.scrollTo({
            top: contactSection.offsetTop,
            behavior: 'smooth'
         });
      }
   }, []);

   const CustomSliderItem = useCallback(({ item, index }) => (
      <div>
         <img src={item.url} alt={`Slide ${index + 1}`} style={{ width: '100%' }} />
         <button onClick={() => handleButtonClick(index)}>Button</button>
      </div>
   ), [handleButtonClick]);

   return (
      <div className="header">
         <div className="parent-container">
            <div className="button-container">
               <img src="img/portfolio/tap-2.png" alt="Icon" className="icon-animation"/>
               <button className="get-in-touch-button" onClick={handleButtonClick}>Get in Touch</button>
               <img src="img/portfolio/tap.png" alt="Icon" className="icon-animation1"/>
            </div>
         </div>
         <SimpleImageSlider
            width='100%'
            height='60%'
           
            images={sliderImages1.length ? sliderImages1 : sliderImages}
            showBullets={true}
            showNavs={true}
            autoPlay={true}
            autoPlayDelay={3}
            CustomSlide={CustomSliderItem}
         />
      </div>
   );
}
